export enum WebPushTypesEnum {
  NewMessage = 1,
  NewInvite,
  NewRequest,
  AnswerInvite,
  AnswerRequest,
  PostComment,
  PostCommentAnswer,
  MeetupRequest
}
